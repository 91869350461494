import { render } from "./Correction.vue?vue&type=template&id=3bed9268&scoped=true"
import script from "./Correction.vue?vue&type=script&lang=js"
export * from "./Correction.vue?vue&type=script&lang=js"

import "./Correction.vue?vue&type=style&index=0&id=3bed9268&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-3bed9268"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3bed9268"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3bed9268', script)) {
    api.reload('3bed9268', script)
  }
  
  module.hot.accept("./Correction.vue?vue&type=template&id=3bed9268&scoped=true", () => {
    api.rerender('3bed9268', render)
  })

}

script.__file = "src/page/correction/Correction.vue"

export default script