<template>
  <section id="tutoringContent">
    <div class="app_desktop">
      <iframe
        src="https://app.tutoring.co.kr/pages/proofReading?p=https://tutoring.co.kr"
        id="corFrame"
        style="border: 0; display: block; overflow: hidden; width: 100%; min-height: 500px"
      ></iframe>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Correction',
};
</script>

<style scoped lang="scss">
body{
  overflow: visible !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */  
}
body::-webkit-scrollbar{
  display: none !important;
}
</style>

